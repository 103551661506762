import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const ThemeDataProvider = ({ children }) => {
  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      allPrismicTheme {
        nodes {
          data {
            theme_name
            footer_bg_color
            footer_color
            header_bg_color
            header_color
            page_bg_color
            page_color
            page_link_color
            fonts {
              font_usage
              google_font
            }
            activ
          }
        }
      }
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return React.Children.only(children({ data: queryData }))
}

export default ThemeDataProvider
